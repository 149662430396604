import axios, { getAxiosConfig } from "@/axios";
import { default as getConfigValue } from "@/utils/config.js";
const apiBaseUrl = getConfigValue("apiBaseUrl");

export default async function fetchProductCatalog(data) {
  const axiosConfig = getAxiosConfig();
  const url =
    apiBaseUrl +
    "api/rest/v1/vtcmenu/"+data.endpoint+"?vtcHost=" +
    data.vtcHost +
    "&vtcPort=" +
    data.vtcPort;
  return await axios
    .get(url, axiosConfig)
    .then((r) => Promise.resolve(r))
    .catch((e) => Promise.reject(e));
}
